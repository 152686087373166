import {
  setConditionList,
  setLocationList,
  setSelectClientList, setSelectInventoryLockCodeList, setSelectOriginList,
  setSelectOwnerList,
  setSkuList
} from "../../../api/common";
import {PAGER} from "../../../constant/config";
import {clientMagnifierList} from "../../../api/comMagnifier";

const defaultPaginationInfo = {
  PAGE_NUM: PAGER.MIN_PAGE_NUM,
  PAGE_SIZE: PAGER.MAX_PAGE_SIZE
}

export const configInfo = {
  CLIENT_ID: {
    data: [
      {
        "key": "CLIENT_ID",
        "label": "客户端ID",
        "type": "input",
        "placeholder": "",
        "showInForm": true,
      },
      {
        "key": "NAME",
        "label": "名称",
        "type": "input",
        "placeholder": "",
        "showInForm": true
      }
    ],
    fetchData: async (params) => {
      return await clientMagnifierList({
        CRITERION_MAP:{
          ...params,
        },
        CRITERION_SORT_MAP:{
          CLIENT_ID:'asc'
        },
        ...defaultPaginationInfo
      });
    }
  },
  JOB_CLIENT_ID: {
    data: [
      {
        "key": "CLIENT_ID",
        "label": "客户端ID",
        "type": "input",
        "placeholder": "",
        "showInForm": true,
      },
      {
        "key": "NAME",
        "label": "名称",
        "type": "input",
        "placeholder": "",
        "showInForm": true
      }
    ],
    fetchData: async (params) => {
      return await clientMagnifierList({
        CRITERION_MAP:{
          ...params,
        },
        CRITERION_SORT_MAP:{
          CLIENT_ID:'asc'
        },
        ...defaultPaginationInfo
      });
    }
  },
  OWNER_ID: {
    data: [
      {
        "key": "OWNER_ID",
        "label": "所有者",
        "type": "input",
        "placeholder": "",
        "showInForm": true
      },
      {
        "key": "NOTES",
        "label": "注释",
        "type": "input",
        "placeholder": "",
        "showInForm": true,
      },
    ],
    fetchData: async (params) => {
      return await setSelectOwnerList({
        ...params,
        ...defaultPaginationInfo
      });
    }
  },
  LOCATION_ID: {
    data: [
      {
        "key": "LOCATION_ID",
        "label": "条件",
        "type": "input",
        "placeholder": "",
        "showInForm": true
      },
      {
        "key": "NOTES",
        "label": "注释",
        "type": "input",
        "placeholder": "",
        "showInForm": true,
      },
    ],
    fetchData: async (params) => {
      return await setLocationList({
        ...params,
        ...defaultPaginationInfo
      });
    }
  },
  SKU_ID: {
    data: [
      {
        "key": "SKU_ID",
        "label": "SKU",
        "type": "input",
        "placeholder": "",
        "showInForm": true
      },
      {
        "key": "DESCRIPTION",
        "label": "描述",
        "type": "input",
        "placeholder": "",
        "showInForm": true,
      },
    ],
    fetchData: async (params) => {
      return await setSkuList({
        ...params,
        ...defaultPaginationInfo
      });
    }
  },
  CONDITION_ID:{
    data: [
      {
        "key": "CONDITION_ID",
        "label": "条件",
        "type": "input",
        "placeholder": "",
        "showInForm": true
      },
      {
        "key": "NOTES",
        "label": "注释",
        "type": "input",
        "placeholder": "",
        "showInForm": true,
      },
    ],
    fetchData: async (params) => {
      return await setConditionList({
        ...params,
        ...defaultPaginationInfo
      });
    }
  },
  LOCK_CODE:{
    data: [
      {
        "key": "LOCK_CODE",
        "label": "锁定代码",
        "type": "magnifier",
        "placeholder": "",
        "showInForm": true
      },
      {
        "key": "NOTES",
        "label": "注释",
        "type": "input",
        "placeholder": "",
        "showInForm": true,
      },
    ],
    fetchData: async (params) => {
      return await setSelectInventoryLockCodeList({
        ...params
      });
    }
  },
  ORIGIN_ID:{
    data: [
      {
        "key": "ORIGIN_ID",
        "label": "来源",
        "placeholder": "",
        "showInForm": true
      },
      {
        "key": "NOTES",
        "label": "注释",
        "type": "input",
        "placeholder": "",
        "showInForm": true,
      },
    ],
    fetchData: async (params) => {
      return await setSelectOriginList({
        ...params
      });
    }
  },
}

const SINGEL_INFO = function (key,label,showInForm) {
  return [
    {
      key,
      label,
      "type": "input",
      "placeholder": "",
      showInForm
    }
  ]
}

/*
* 根据字段名获取不同的表单
* @param key     处理过后的字段
* @param itemKey 原始key字段（由于存在多张表在一个列表中的情况，字段为 表名.字段名）返回数据为 {表名.字段名:value}
* */
export const getFormComfig = (key,label,itemKey) => {
  let data = []
  if (configInfo[key]) {
    data = configInfo[key].data || []
  }else{
    data = SINGEL_INFO(itemKey,label,true)
  }
  return data.filter(item => item.showInForm)
}


/*
* 根据字段名获取不同的表格
* @param key     处理过后的字段
* @param itemKey 原始key字段（由于存在多张表在一个列表中的情况，字段为 表名.字段名）返回数据为 {表名.字段名:value}
* */
export const getTableComfig = (key,label,itemKey) => {
  let data = []
  if (configInfo[key]) {
    data = configInfo[key].data || []
  }else{
    data = SINGEL_INFO(itemKey,label,true)
  }
  let result = data.map(item => {
    return {
      label: item.label,
      value: item.key,
      checked: false,
      sortable: false
    }
  })
  return [...result]
}


<template>
  <div class="comMagnifierDialog">
    <wms-dialog width="40%" @close="handleClose" @confirm="handleConfirm" title="查找选择" isHideFooter>
      <com-form
        ref="filterForm"
        label-width="120px"
        :col="2"
        :configs="filterFormConfig"
        showType="search"
        @formMounted="init"
      >
      </com-form>
     <!-- <div style="position: absolute;top: 95px;right: 5px">
        <i class="el-icon-search" @click="init" title="查询" style="cursor: pointer"></i>
      </div>-->
      <div class="magnifier-table">
        <div class="magnifier-table-header">选择所需记录 - {{importData.length}} 记录</div>

        <el-table
          :data="importData"
          @selection-change="selectionChange"
          @row-click="rowClick"
          highlight-current-row
          ref="configTable"
          max-height="270"
          border
          size="mini"
          style="width: 100%">
          <el-table-column
            key="selection"
            v-if="isSearch && !['single','empty'].includes(searchType.selectDataType)"
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column
            key="radio"
            v-if="searchType.selectDataType === 'single' || !isSearch"
            width="55">
            <template slot-scope="scope">
              <div class="table-radio-hiddenLabel">
                <el-radio v-model="singleChecked" :label="scope.row[tableColumn[0].value]"></el-radio>
              </div>
            </template>
          </el-table-column>
          <template v-for="(item,index) in tableColumn">
            <el-table-column
              :label="item.label">
              <template slot-scope="scope">
                <div>{{scope.row[item.value]}}</div>
              </template>
            </el-table-column>
          </template>
        </el-table>


        <div class="magnifier-table-footer" v-if="isSearch">
          <div class="table-footer__select">
            <el-select v-model="searchType" placeholder="请选择" size="small" value-key="value" @change="searchTypeChange">
              <el-option
                v-for="item in OPERATOR_SEARCH"
                :key="item.value"
                :label="item.label"
                :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="table-footer__con">
            <template v-if="!searchType.isRadio">
              {{selectData.right}}
            </template>
            <template v-else>
              <el-radio v-model="searchTypeIsRadioValue" label="left">
                {{selectData.left}}
              </el-radio>
              <el-radio v-model="searchTypeIsRadioValue" label="right">
                {{selectData.right}}
              </el-radio>
            </template>
          </div>
        </div>
      </div>
      <div class="magnifier-footer">
        <el-button type="primary" size="small" @click="handleConfirm">确定</el-button>
        <el-button type="primary" size="small" @click="doSearch">搜索</el-button>
        <el-button type="primary" size="small" @click="clear">清除</el-button>
        <el-button type="primary" size="small" @click="cancel">取消</el-button>
      </div>
    </wms-dialog>
  </div>
</template>

<script>
  import {getFormComfig, getTableComfig, configInfo} from './config'
  import {EMUN_OPERATOR_VALUE_TEMPLATE, OPERATOR_SEARCH, SET_QUERY_SHOW_KEY_SPLIT} from "../../../constant";
  import {EMUN_OPERATOR} from "../../../constant/comMagnifier";

  export default {
    name: 'comMagnifierDialog',
    props: {
      injectData: {
        type: Object,
      },
      /*
     * 展示类型
     * search =>  页面查询数据，作为查询条件时
     * info => 数据增删改查详情数据时
     * */
      showType: {
        type: String
      }
    },
    data() {
      const filterFormConfig = getFormComfig(this.injectData.key,this.injectData.label,this.injectData.itemKey)
      const tableColumn = getTableComfig(this.injectData.key,this.injectData.label,this.injectData.itemKey)
      return {
        filterFormConfig: filterFormConfig,
        tableColumn: tableColumn,
        importData: [],
        selectData: {
          left: '',
          right: ''
        },
        currentSelection: '',
        dropColumn: tableColumn,
        searchType: {
          value:EMUN_OPERATOR.SINGLE_VALUE,
          label:'等于',
          template:`=${EMUN_OPERATOR_VALUE_TEMPLATE.right}`,
          selectDataType:'multiple'
        },
        options: [],
        OPERATOR_SEARCH: OPERATOR_SEARCH,
        searchTypeIsRadioValue: 'right',
        currentTableSelection: [],
        singleChecked:null
      }
    },
    computed: {
      isSearch() {
        return this.showType === 'search'
      },
    },
    watch: {
      searchTypeIsRadioValue() {
        this.$refs.configTable.clearSelection();
      }
    },
    mounted() {
    },
    methods: {
      /*
      * init
      * */
      init() {
        if (!this.injectData.key) return;
        const params = {
          ...this.$refs.filterForm.formData
        }
        if(configInfo[this.injectData.key]){
          this.getConfigData(params)
        }else{
          this.getSingelData(params)
        }

      },
      async getConfigData(params){
        const {status, data, message} = await configInfo[this.injectData.key].fetchData(params);
        if (status === 200) {
          this.importData = data.list
        }
      },
      async getSingelData(){
        const params = {
          key:this.injectData.itemKey,
          value:this.$refs.filterForm.formData[this.injectData.itemKey]
        }
        if(!this.injectData.tableName) return this.$message.error('tableError')
        const {status,data} = await this.$store.dispatch(this.injectData.tableName,params)
        if (status === 200) {
          this.importData = data.list
        }
      },

      cancel() {
        this.handleClose()
      },
      clear() {
        this.selectData = {left: '', right: ''}
        this.$refs.configTable.clearSelection();
        this.singleChecked = null
      },
      doSearch() {
        this.init()
      },
      /*
      * 表格行点击
      * */
      rowClick(row, column, event) {
        if(this.isSearch){
          if (this.searchType.selectDataType === 'single') {
            let list = [{...row}]
            this.selectionChange(list)
            this.singleChecked = row[this.tableColumn[0].value]
            return
          } else if (this.searchType.selectDataType === 'empty') {
            this.$refs.configTable.clearSelection(row);
            return
          }
          this.$refs.configTable.toggleRowSelection(row);
        }else {
          if (this.currentTableSelection.length) {
            this.$refs.configTable.clearSelection(row);
          }
          this.$refs.configTable.toggleRowSelection(row);
        }
      },
      /*
      * 选中数据
      * 介于跟不介于 是单选
      *
      * */
      selectionChange(list) {
        if (!list) return;
        let listData = list
        this.currentTableSelection = list
        this.currentSelection = listData.map(item => {
          return item[this.injectData.key]
        }).join(SET_QUERY_SHOW_KEY_SPLIT)
        if (this.searchTypeIsRadioValue) {
          this.selectData[this.searchTypeIsRadioValue] = this.currentSelection
        }
      },
      /*
      * 更改选择操作符方式
      * */
      searchTypeChange() {
        this.searchTypeIsRadioValue = 'right'
        this.clear()
      },
      handleClose() {
        this.$emit('close')
      },
      /*
      * 替代输出模板
      * return  输出数据
      * */
      setConfirmData() {
        if (!this.searchType.template) return this.selectData.right;
        let result = this.searchType.template
        result = result.replace(/\$right\$/g, this.selectData.right)
        result = result.replace(/\$left\$/g, this.selectData.left)
        //查看的是不需要操作符
        if(!this.isSearch){
          result = this.selectData.right
        }
        return result
      },
      /*
      * 输出值
      * */
      handleConfirm() {
        let resultData = this.setConfirmData()
        this.$emit('confirm', resultData)
      },
    }
  }
</script>
<style lang="scss" scoped>
  .magnifier-footer {
    text-align: center;
    margin-top: 10px;
    border-top: 1px solid #d0d0d0;
    padding-top: 10px;
  }

  .magnifier-table-footer {
    display: flex;
    flex: 1 2;
    margin-top: 10px;

    .table-footer__con {
      padding: 0 20px;
      line-height: 18px;
      display: flex;
      justify-content: space-around;
    }
  }

  .magnifier-table-header {
    text-align: center;
    margin: 4px 0;
    border: 1px solid #d0d0d0;
  }

</style>
<style lang="scss">
  .comMagnifierDialog {
    .el-table__header-wrapper .el-checkbox {
      display: none !important
    }
  }
  .table-radio-hiddenLabel{
    .el-radio__label{
      display: none;
    }
  }
</style>
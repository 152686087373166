<template>
  <div>
    <el-input
      type="input"
      v-model="currentValue"
      :placeholder="placeholder"
      :disabled="disabled"
      @blur="emitValue(currentValue)"
    >
      <i slot="suffix" class="el-input__icon el-icon-search" @click="openModal('comMagnifierDialog')" v-if="!disabled"></i>
    </el-input>
    <componet :is="model" :injectData="injectData" @confirm="confirm" :showType="showType" @close="close"></componet>
  </div>
</template>

<script>
  /* TODO Tracking Level 跟踪级别、Pack Config 打包配置、Address 地址、Site ID 站点 */
  import comMagnifierDialog from './dialog/comMagnifierDialog'
  export default {
    name: 'comMagnifier',
    props:{
      placeholder: String,
      disabled: Boolean,
      value: [String, Number],
      itemKey: String,
      itemLabel: String,
      tableName: String,
      /*
      * 展示类型
      * search =>  页面查询数据，作为查询条件时
      * info => 数据增删改查详情数据时
      * */
      showType:{
        type: String
      }
    },
    data() {
      return {
        currentValue:'',
        model:null,
        injectData:{},
      }
    },
    components:{comMagnifierDialog},
    watch:{
      value(val){
        if(this.currentValue !== val){
          this.currentValue = this.value
        }
      }
    },
    computed:{
      propKey(){
        let key = this.itemKey
        if(this.itemKey.includes('$')){
          key = this.itemKey.split('$')[1]
        }

        return key
      }
    },
    mounted() {
      this.$nextTick(()=>{
        this.init();
      })
    },
    methods: {
      init(){
        this.currentValue = this.value
      },
      openModal(model,params){
        this.model = model
        this.injectData = {
          key:this.propKey,
          itemKey:this.itemKey,
          label:this.itemLabel,
          tableName:this.tableName,
          ...params
        }
      },
      close(){
        this.model = null
      },
      emitValue(val){
        this.$emit('input',val)
      },
      confirm(val){
        this.currentValue = val
        this.emitValue(val)
        this.close()
      }
    }
  }
</script>

<style scoped>

</style>
